import { create } from 'zustand';

interface Store {
  isCreateSectionOpen: boolean;
  isSettingsOpen: boolean;
  setIsCreateSectionOpen: (isOpen: boolean) => void;
  setIsSettingsOpen: (isOpen: boolean) => void;
  sidebarWidth: string;
  setSidebarWidth: (width: string) => void;
  isCollapse: boolean;
  setIsCollapse: (value: boolean) => void;
  isTextHidden: boolean;
  setIsTextHidden: (value: boolean) => void;
}

export const useStore = create<Store>()(set => ({
  isCreateSectionOpen: false,
  isSettingsOpen: false,
  setIsCreateSectionOpen: isOpen =>
    set(() => ({ isCreateSectionOpen: isOpen })),
  setIsSettingsOpen: isOpen => set(() => ({ isSettingsOpen: isOpen })),
  sidebarWidth: '240px',
  setSidebarWidth: width => set(() => ({ sidebarWidth: width })),
  isCollapse: false,
  setIsCollapse: isCollapse =>
    set(() => ({
      isCollapse,
    })),
  isTextHidden: false,
  setIsTextHidden: value =>
    set(() => ({
      isTextHidden: value,
    })),
}));
