import { css } from '@superset-ui/core';
import React from 'react';
import { Flex } from './commons/Flex';
import Linker from './commons/Linker';
import { useStore } from './store';
import { Menu } from './types';
import { transition } from './utils/css';

function MenuItem({
  name,
  icon,
  isActive,
  path,
  fontWeight = 600,
  className,
}: Menu & { isActive: boolean; className?: string }) {
  const { isCollapse, isTextHidden } = useStore();

  return (
    <Linker
      decoration={css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-items: center;
        ${isTextHidden && 'width : 48px; height: 48px;'}
        padding: ${isTextHidden ? '4px' : '4px 8px'};
        background: ${isActive ? '#106ff5' : 'none'};
        border: none;
        margin-top: 8px;
        border-radius: 8px;
        &:hover {
          background: #106ff5;
          color: white;
          text-decoration: none;
        }
        &:focus {
          color: white;
          text-decoration: none;
        }
        text-decoration: none;
        color: white;
      `}
      {...{ path, className }}
    >
      <Flex
        align="center"
        css={css`
          min-width: 1.5em;
        `}
      >
        {icon}
      </Flex>
      {!isTextHidden && (
        <h5
          css={css`
            text-overflow: ellipsis;
            text-wrap: nowrap;
            font-weight: ${fontWeight};
            overflow: hidden;
            flex: 1 1 auto;
            font-size: 1em;
            ${transition(isCollapse)}
          `}
        >
          {name}
        </h5>
      )}
    </Linker>
  );
}

export default MenuItem;
