import React from 'react';
import SearchItem from './SearchItem';
import type { ISearchItem } from './types';

interface Props {
  results: ISearchItem[];
}
function SearchList({ results }: Props) {
  return (
    <>
      {results.map(result => (
        <SearchItem key={result.path} {...result} />
      ))}
    </>
  );
}

export default SearchList;
