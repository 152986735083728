import { css } from '@superset-ui/core';
import { CircleArrowLeft, CircleUserRound } from 'lucide-react';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { Container } from './commons/Container';
import { Flex } from './commons/Flex';
import SectionGroup from './commons/SectionGroup';
import { useSettingsMenu } from './hooks/useSettingsMenu';
import { useStore } from './store';

function SettingsTab() {
  const { setIsSettingsOpen, sidebarWidth } = useStore();
  const menus = useSettingsMenu();

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (e.target === null) return;

      const isALink = (e.target as HTMLElement).className.includes('linker');

      if (isALink) {
        setIsSettingsOpen(false);
      }
    };

    document.addEventListener('click', onClick);

    return () => document.removeEventListener('click', onClick);
  }, [setIsSettingsOpen]);

  const isDesktop = useMediaQuery('(min-width : 768px)');

  return (
    <Container width={isDesktop ? sidebarWidth : '100%'} zIndex={60}>
      <Flex
        style={{ height: '100%' }}
        direction="column"
        justify="space-between"
      >
        <div style={{ width: '100%' }}>
          <Flex direction="row" align="center" gap="8px">
            <button
              onClick={() => setIsSettingsOpen(false)}
              css={css`
                background: none;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
              type="button"
            >
              <CircleArrowLeft />
            </button>
            <h6
              css={css`
                font-size: 1em;
              `}
            >
              Settings
            </h6>
          </Flex>
          <div
            css={css`
              margin-left: 8px;
            `}
          >
            {menus.map(menu => (
              <SectionGroup
                key={menu.name}
                name={menu.name}
                icon={menu.icon}
                actions={menu.actions}
              />
            ))}
            <SectionGroup
              name="User"
              icon={<CircleUserRound />}
              actions={[
                // { name: 'Profile', url: '/profile/', hasAccess: true },
                { name: 'Info', url: '/users/userinfo/', hasAccess: true },
              ]}
            />
          </div>
        </div>
        <a
          css={css`
            padding: 8px 16px;
            background: #fb0000;
            color: white;
            border: none;
            border-radius: 8px;
            text-decoration: none;
            text-align: center;
            font-size: 1em;
            width: 100%;
            &:hover,
            &:focus {
              text-decoration: none;
              color: white;
            }
          `}
          href="/logout"
        >
          Logout
        </a>
      </Flex>
    </Container>
  );
}

export default SettingsTab;
