export interface Menu {
  name: string;
  icon: JSX.Element;
  type: MenuType;
  path: string;
  fontWeight?: React.CSSProperties['fontWeight'];
  hideText?: boolean;
}

export enum MenuType {
  Button,
  Collapse,
}

export interface ISearchItem {
  name: string;
  type: SearchType;
  path: string;
}

export enum SearchType {
  Dashboard = 'Dashboard',
  Chart = 'Chart',
  Dataset = 'Dataset',
}

export interface DashboardResult {
  dashboard_title: string;
  url: string;
}

export type ChartResult = {
  slice_name: string;
  url: string;
};

export type DatasetResult = {
  table_name: string;
  explore_url: string;
};
