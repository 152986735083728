import { SupersetClient } from '@superset-ui/core';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import rison from 'rison';
import {
  ChartResult,
  DashboardResult,
  DatasetResult,
  ISearchItem,
  SearchType,
} from '../types';

const fetchResult = async (
  type: 'dashboard' | 'chart' | 'dataset',
  columns: string[],
  search: string,
) => {
  const searchCond = [{ col: columns[0], opr: 'ct', value: search }];
  const query = rison.encode({
    columns,
    filters: searchCond,
  });

  const dataEndpoint = `/api/v1/${type}/?q=${query}`;

  const json = await SupersetClient.get({ endpoint: dataEndpoint });

  return json;
};

function useSearch() {
  const [isActive, setIsActive] = useState(false);
  const [search, setSearch] = useState('');
  const [results, setResults] = useState<ISearchItem[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isSearching = search.length > 0;
  const isNotFound = isSearching && !isFetching && results.length === 0;

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (e.target === null) return;
      if (containerRef.current === null)
        throw new Error('containerRef need to be bind before use');
      if (inputRef.current === null)
        throw new Error('inputRef need to be bind before use');

      const isInsideContainer = containerRef.current.contains(e.target as Node);

      setIsActive(isInsideContainer);

      if (isInsideContainer) {
        inputRef.current?.focus();

        const isResults = (e.target as HTMLElement).className.includes(
          'search_result',
        );

        if (isResults) {
          setSearch('');
          setResults([]);
          setIsActive(false);
        }
      }
    };

    document.addEventListener('click', onClick);

    return () => document.removeEventListener('click', onClick);
  }, []);

  const getResult = useCallback(async () => {
    try {
      const [dashboardsRes, chartsRes, datasetsRes] = await Promise.all([
        fetchResult('dashboard', ['dashboard_title', 'url'], search),
        fetchResult('chart', ['slice_name', 'url'], search),
        fetchResult('dataset', ['table_name', 'explore_url'], search),
      ]);
      const dashboards = (dashboardsRes.json.result as DashboardResult[]).map(
        dashboard => ({
          name: dashboard.dashboard_title,
          type: SearchType.Dashboard,
          path: dashboard.url,
        }),
      );

      const charts = (chartsRes.json.result as ChartResult[]).map(chart => ({
        name: chart.slice_name,
        type: SearchType.Chart,
        path: chart.url,
      }));

      const datasets = (datasetsRes.json.result as DatasetResult[]).map(
        chart => ({
          name: chart.table_name,
          type: SearchType.Dataset,
          path: chart.explore_url,
        }),
      );

      setResults([...dashboards, ...charts, ...datasets]);
      // eslint-disable-next-line no-empty
    } catch (err) {
    } finally {
      setIsFetching(false);
    }
  }, [search]);

  const debounced = useMemo(() => debounce(getResult, 1000), [getResult]);

  useEffect(() => {
    if (!isSearching) return;

    setIsFetching(true);
    debounced();

    // eslint-disable-next-line consistent-return
    return () => debounced.cancel();
  }, [isSearching, debounced]);

  useEffect(() => {
    setResults([]);
  }, [search]);

  return {
    containerRef,
    inputRef,
    isActive,
    isNotFound,
    search,
    setSearch,
    results,
    isSearching,
    isFetching,
  };
}

export default useSearch;
