import { SupersetClient } from '@superset-ui/core';
import { useCallback, useEffect, useState } from 'react';
import rison from 'rison';
import { event } from '../events';
import type { DashboardResult } from '../types';

interface Favorite {
  name: string;
  path: string;
}

function useFavoriteDashboard() {
  const [favorites, setFavorites] = useState<Favorite[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetching = useCallback(async () => {
    const search = [{ col: 'id', opr: 'dashboard_is_favorite', value: true }];
    const query = rison.encode({
      keys: ['none'],
      columns: ['created_on_delta_humanized', 'dashboard_title', 'url'],
      filters: search,
      order_column: 'changed_on',
      order_direction: 'desc',
      page: 0,
      page_size: 100,
    });

    const dataEndpoint = `/api/v1/dashboard/?q=${query}`;

    setIsLoading(true);

    try {
      const { json } = await SupersetClient.get({ endpoint: dataEndpoint });
      const favorites = (json.result as DashboardResult[]).map(res => ({
        name: res.dashboard_title,
        path: res.url,
      }));
      setFavorites(favorites);
      // eslint-disable-next-line no-empty
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetching();
  }, [fetching]);

  useEffect(() => {
    const onEvent = () => {
      fetching();
    };

    event.addEventListener('favorite', onEvent);

    return () => event.removeEventListener('favorite', onEvent);
  }, [fetching]);

  return { favorites, isLoading };
}

export default useFavoriteDashboard;
