import React from 'react';
import { css, styled } from '@superset-ui/core';
import { Search } from 'lucide-react';
import { Flex } from './commons/Flex';
import SearchList from './SearchList';
import useSearch from './hooks/useSearch';

const Input = styled.input`
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-weight: 600;
  ::placeholder {
    color: white;
  }
`;

function SearchInput() {
  const {
    containerRef,
    inputRef,
    isActive,
    isSearching,
    isNotFound,
    search,
    setSearch,
    results,
    isFetching,
  } = useSearch();

  return (
    <Flex
      ref={containerRef}
      direction="column"
      css={css`
        background: ${isActive ? '#106FF5' : '#ffffff80'};
        border-radius: 8px;
        overflow: hidden;
        margin: 12px 0;
        z-index: 55;
      `}
    >
      <Flex
        css={css`
          padding: 12px;
          width: 100%;
        `}
        direction="row"
        align="center"
        gap="4px"
      >
        <Search size="1em" />
        <Input
          ref={inputRef}
          placeholder="Search..."
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </Flex>

      {isSearching && (
        <div
          css={css`
            background: white;
            width: 100%;
            color: black;
            padding: 6px 12px;
            max-height: 200px;
            overflow: auto;
          `}
        >
          {(isNotFound || isFetching) && (
            <h6
              css={css`
                font-weight: 400;
                font-size: 0.8em;
              `}
            >
              {isNotFound && 'No result found on this workspace'}
              {isFetching && 'Searching...'}
            </h6>
          )}
          <SearchList {...{ results }} />
        </div>
      )}
    </Flex>
  );
}

export default SearchInput;
