import React from 'react';
import { Settings, User } from 'lucide-react';
import { css } from '@superset-ui/core';
import { useMediaQuery } from 'usehooks-ts';
import { Flex } from './commons/Flex';
import { useStore } from './store';
import useUser from './hooks/useUser';
import { transition } from './utils/css';

function UserSection() {
  const { setIsSettingsOpen, isTextHidden, isCollapse } = useStore();
  const { isAuthed, firstName, lastName } = useUser();

  const isDesktop = useMediaQuery('(min-width : 1024px)');

  if (!isAuthed) return null;

  return (
    <Flex direction="row" justify="space-between" align="center">
      <Flex
        style={isDesktop ? { flex: 1 } : undefined}
        direction="row"
        gap="16px"
        justify="center"
        align="center"
      >
        <div
          style={{
            height: 'fit-content',
            padding: '8px',
            borderRadius: '100px',
            background: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <User color="black" />
        </div>
        {!isTextHidden && (
          <div>
            <h6
              css={css`
                ${transition(isCollapse)}
              `}
              style={{ fontSize: '12px' }}
            >
              User
            </h6>
            <h6
              css={css`
                ${transition(isCollapse)}
              `}
              style={{ fontSize: '12px' }}
            >{`${firstName} ${lastName}`}</h6>
          </div>
        )}
      </Flex>
      {!isTextHidden && (
        <button
          type="button"
          onClick={() => setIsSettingsOpen(true)}
          css={css`
            background: none;
            border: none;
            display: flex;
            align-items: center;
            justify-items: center;
            ${transition(isCollapse)}
          `}
        >
          <Settings size="1.5em" />
        </button>
      )}
    </Flex>
  );
}

export default UserSection;
