import { Pin } from 'lucide-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useFavoriteDashboard from './hooks/useFavoriteDashboard';
import MenuItem from './MenuItem';
import { MenuType } from './types';

function FavoriteSection() {
  const { favorites } = useFavoriteDashboard();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div style={{ fontSize: 14 }}>
      {favorites.map(({ name, path }) => (
        <MenuItem
          key={path}
          fontWeight={400}
          name={name}
          isActive={pathname === path}
          icon={<Pin size="1em" />}
          path={path}
          type={MenuType.Button}
        />
      ))}
    </div>
  );
}

export default FavoriteSection;
