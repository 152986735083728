import { css } from '@superset-ui/core';
import {
  ChevronDown,
  ChevronUp,
  FlaskConical,
  Save,
  History,
} from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Flex } from './commons/Flex';
import { useStore } from './store';
import { Menu } from './types';
import { transition } from './utils/css';

type IconTileProps = Omit<Menu, 'type'> & {
  size?: 'normal' | 'sm';
};

const IconTile = ({ name, icon, size = 'normal' }: IconTileProps) => {
  const { isCollapse, isTextHidden } = useStore();
  return (
    <Flex
      direction="row"
      align="center"
      justify={isTextHidden ? 'center' : 'start'}
      gap="8px"
      style={{ flex: 1, fontSize: size }}
    >
      <div
        css={css`
          min-width: 1em;
        `}
      >
        {icon}
      </div>
      {!isTextHidden && size === 'normal' && (
        <h5
          css={css`
            ${transition(isCollapse)}
          `}
          style={{ fontSize: '1em' }}
        >
          {name}
        </h5>
      )}
      {!isTextHidden && size === 'sm' && (
        <h6
          css={css`
            text-wrap: nowrap;
            ${transition(isCollapse)}
          `}
          style={{ fontSize: '0.8em' }}
        >
          {name}
        </h6>
      )}
    </Flex>
  );
};

const subMenus: Omit<Menu, 'type'>[] = [
  {
    name: 'SQL Lab',
    icon: <FlaskConical size="1em" />,
    path: '/sqllab/',
  },
  {
    name: 'Saved Query',
    icon: <Save size="1em" />,
    path: '/savedqueryview/list/',
  },
  {
    name: 'Query History',
    icon: <History size="1em" />,
    path: '/sqllab/history/',
  },
];

type SubButtonProps = Omit<Menu, 'type'> & { isActive: boolean };

const SubButton = (props: SubButtonProps) => {
  const { isTextHidden } = useStore();
  return (
    <NavLink
      type="button"
      to={props.path}
      css={css`
        display: block;
        border: none;
        background: ${props.isActive ? '#106ff5b2' : 'none'};
        padding: ${isTextHidden ? '1rem' : '2px 16px'};
        &:hover {
          background: #106ff5b2;
          color: white;
          text-decoration: none;
        }
        &:focus {
          color: white;
          text-decoration: none;
        }
        text-decoration: none;
        color: white;
      `}
    >
      <IconTile {...props} size="sm" />
    </NavLink>
  );
};
function CollapseButton(menu: Menu) {
  const [isCollapse, setIsCollapse] = useState(true);
  const location = useLocation();
  const { pathname } = location;
  const { isCollapse: isSidebarCollapse, isTextHidden } = useStore();

  useEffect(() => {
    const isSomePathActive = subMenus.some(menu =>
      pathname.startsWith(menu.path),
    );
    setIsCollapse(!isSomePathActive);
  }, [pathname]);

  return (
    <Flex direction="column">
      <button
        type="button"
        onClick={() => setIsCollapse(!isCollapse)}
        css={css`
          display: flex;
          gap: 8px;
          ${isTextHidden && 'justify-content : center;'}
          align-items: center;
          padding: 4px 8px;
          background: ${!isCollapse ? '#106ff5' : 'none'};
          border-radius: ${!isCollapse ? '8px 8px 0 0' : '8px'};
          border: none;
          width: ${!isTextHidden ? '100%' : '48px'};
          ${isTextHidden && 'height : 48px;'}
          margin-top: 8px;
          &:hover {
            background: #106ff5;
          }
        `}
      >
        <IconTile {...menu} />
        {!isSidebarCollapse &&
          (isCollapse ? (
            <ChevronDown size="1.25em" />
          ) : (
            <ChevronUp size="1.25em" />
          ))}
      </button>
      {!isCollapse && (
        <div
          css={css`
            background: #106ff54d;
            border-radius: 0 0 8px 8px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: ${!isTextHidden ? '100%' : '48px'};
          `}
        >
          {subMenus.map(menu => (
            <SubButton
              isActive={pathname === menu.path}
              key={menu.name}
              {...menu}
            />
          ))}
        </div>
      )}
    </Flex>
  );
}

export default CollapseButton;
