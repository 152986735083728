import { CSSProperties } from '@emotion/react/node_modules/@emotion/serialize';
import { styled } from '@superset-ui/core';

interface FlexProps {
  direction?: CSSProperties['flexDirection'];
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  gap?: CSSProperties['gap'];
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => props.direction ?? 'row'};
  justify-content: ${props => props.justify ?? 'start'};
  align-items: ${props => props.align ?? 'start'};
  gap: ${props => props.gap ?? 0};
`;
