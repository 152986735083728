import { SerializedStyles } from '@emotion/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import getBootstrapData from 'src/utils/getBootstrapData';
import { useMediaQuery } from 'usehooks-ts';
import { useStore } from '../store';

interface LinkerProps {
  path: string;
  children: React.ReactNode;
  decoration?: SerializedStyles;
  disabled?: boolean;
  className?: string;
}

const backendRoutes = [
  '/users/list/',
  '/roles/list/',
  '/logmodelview/list/',
  '/users/userinfo/',
  '/csvtodatabaseview/form',
  '/columnartodatabaseview/form',
  '/exceltodatabaseview/form',
  '/dashboard/new',
  '/sqllab?new=true',
];

const Linker = ({
  path,
  children,
  decoration,
  disabled,
  className,
}: LinkerProps) => {
  const { setIsCollapse } = useStore();
  const bootstrapData = getBootstrapData();

  const isBackendRender =
    bootstrapData.backend_render || backendRoutes.includes(path);

  const isDesktop = useMediaQuery('(min-width : 768px)');

  if (disabled) {
    return (
      <button type="button" css={decoration} {...{ disabled, className }}>
        {children}
      </button>
    );
  }
  if (isBackendRender) {
    return (
      <a css={decoration} href={path} {...{ className }}>
        {children}
      </a>
    );
  }
  return (
    <NavLink
      onClick={() => {
        if (!isDesktop) {
          setIsCollapse(true);
        }
      }}
      css={decoration}
      to={path}
      {...{ className }}
    >
      {children}
    </NavLink>
  );
};

export default Linker;
