import React, { useEffect, useState } from 'react';
import { CircleUserRound, HardDrive, Settings2, Shield } from 'lucide-react';
import { MenuObjectChildProps } from 'src/types/bootstrapTypes';
import getBootstrapData from 'src/utils/getBootstrapData';

interface SettingMenu {
  name: string;
  icon: JSX.Element;
  actions: {
    name: string;
    url: string;
  }[];
}

export const useSettingsMenu = () => {
  const [menus, setMenus] = useState<SettingMenu[]>([]);

  const getIcon = (menuName: string) => {
    switch (menuName) {
      case 'Security':
        return <Shield />;
      case 'Data':
        return <HardDrive />;
      case 'Manage':
        return <Settings2 />;
      case 'User':
        return <CircleUserRound />;
      default:
        throw new Error('Menu Not Found');
    }
  };

  useEffect(() => {
    const bootstrapData = getBootstrapData();

    const settingsMenus = ['Security', 'Data', 'Manage', 'User'];
    const menus = bootstrapData.common.menu_data.menu
      .filter(
        menu => menu.name !== undefined && settingsMenus.includes(menu.name),
      )
      .map(menu => {
        const newMenu: SettingMenu = {
          name: menu.name as string,
          icon: getIcon(menu.name as string),
          actions:
            menu.childs
              ?.filter(menu => typeof menu === 'object')
              .map(({ name, url }: MenuObjectChildProps) => ({
                name: name as string,
                url: url as string,
              })) ?? [],
        };
        return newMenu;
      });

    setMenus(menus);
  }, []);

  return menus;
};
