import { css } from '@superset-ui/core';
import React from 'react';
import { Flex } from './commons/Flex';
import { ISearchItem } from './types';

function SearchItem({ name, type, path }: ISearchItem) {
  return (
    <a
      href={path}
      className="search_result"
      css={css`
        &:hover {
          text-decoration: none;
        }
        &:focus {
          text-decoration: none;
        }
      `}
    >
      <Flex direction="row" gap="8px" justify="space-between" align="center">
        <h6
          className="search_result"
          css={css`
            font-weight: 400;
            font-size: 1rem;
            color: black;
            flex: 1 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
          `}
        >
          {name}
        </h6>
        <span
          className="search_result"
          css={css`
            font-size: 0.75rem;
            font-weight: 400;
            color: #757575;
          `}
        >
          {type}
        </span>
      </Flex>
    </a>
  );
}

export default SearchItem;
