import { styled } from '@superset-ui/core';
import { CSSProperties } from 'react';

interface ContainerProps {
  zIndex?: CSSProperties['zIndex'];
  width: string;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  width: ${props => props.width};
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #151515;
  padding: 1rem;
  color: white;
  z-index: ${props => props.zIndex ?? 50};
  display: flex;
  flex-direction: column;
  font-size: 14px !important;
  transition: width 0.4s ease-in-out;
`;
