import { css } from '@superset-ui/core';
import { Plus } from 'lucide-react';
import React from 'react';
import Linker from './Linker';

interface Props {
  name: string;
  icon: JSX.Element;
  type?: 'CREATE' | 'BULLET';
  actions: {
    name: string;
    url: string;
    hasAccess?: boolean;
    disabled?: boolean;
  }[];
}

const SectionGroup = ({ name, icon, actions, type = 'BULLET' }: Props) => (
  <>
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 24px;
      `}
    >
      {icon} <h5 style={{ fontSize: '1em' }}>{name}</h5>
    </div>
    {actions
      .filter(({ hasAccess }) => hasAccess || hasAccess === undefined)
      .map(({ name, url, disabled }) => (
        <Linker
          className="linker"
          decoration={css`
            display: flex;
            gap: 8px;
            align-items: center;
            background: none;
            border: none;
            border-radius: 8px;
            margin-top: 8px;
            padding: 8px 16px;
            width: 100%;
            font-size: 12px;
            &:hover {
              background: #106ff5;
              color: white;
              text-decoration: none;
            }
            &:focus {
              color: white;
              text-decoration: none;
            }
            &:disabled {
              opacity: 0.5;
              &:hover {
                background: none;
                color: white;
                text-decoration: none;
              }
            }
            text-decoration: none;
            color: white;
          `}
          path={url}
          disabled={disabled}
        >
          {type === 'CREATE' && <Plus size="1em" />}
          {type === 'BULLET' && (
            <span
              css={css`
                font-size: 1em;
              `}
            >
              •
            </span>
          )}
          {name}
        </Linker>
      ))}
  </>
);

export default SectionGroup;
