import getBootstrapData from 'src/utils/getBootstrapData';

function useUser() {
  const bootstrapData = getBootstrapData();
  const { user } = bootstrapData;

  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const email = user?.email;

  const isAuthed = !!firstName && !!lastName;

  return { isAuthed, firstName, lastName, email };
}

export default useUser;
