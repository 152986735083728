import { Plus } from 'lucide-react';
import React from 'react';
import { css, styled } from '@superset-ui/core';
import { useStore } from './store';
import { transition } from './utils/css';

const Button = styled.button`
  padding: 8px 12px;
  background-color: #106ff5;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  gap: 4px;
  font-size: 1em;
`;

function CreateButton() {
  const { setIsCreateSectionOpen, isCollapse } = useStore();
  return (
    <Button
      css={css`
        ${transition(isCollapse)}
        width : 100%;
      `}
      onClick={() => setIsCreateSectionOpen(true)}
      type="button"
    >
      Create <Plus size="1.1rem" />
    </Button>
  );
}

export default CreateButton;
