import React from 'react';
import workspaceIcon from 'src/assets/images/workspace.png';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { css } from '@superset-ui/core';
import MenuList from './MenuList';
import CreateButton from './CreateButton';
import Divider from './Divider';
import UserSection from './UserSection';
import SearchInput from './SearchInput';
import { Container } from './commons/Container';
import { useStore } from './store';
import SettingsTab from './SettingsTab';
import CreateTab from './CreateTab';
import FavoriteSection from './FavoriteSection';
import useUser from './hooks/useUser';
import { transition } from './utils/css';

function Sidebar() {
  const {
    isCreateSectionOpen,
    isSettingsOpen,
    setIsCollapse,
    sidebarWidth,
    isCollapse,
    isTextHidden,
    setSidebarWidth,
    setIsTextHidden,
    setIsCreateSectionOpen,
    setIsSettingsOpen,
  } = useStore();
  const { isAuthed } = useUser();

  const workspaceName = 'WorkSpace';

  if (!isAuthed) return null;

  const handleCollapse = () => {
    if (isCollapse) {
      setSidebarWidth('240px');
      setIsTextHidden(false);
      setTimeout(() => {
        setIsCollapse(false);
      }, 200);
    } else {
      setTimeout(() => {
        setIsTextHidden(true);
        setSidebarWidth('80px');
      }, 100);
      setIsCollapse(true);
      setIsCreateSectionOpen(false);
      setIsSettingsOpen(false);
    }
  };

  return (
    <>
      {isCreateSectionOpen && <CreateTab />}
      {isSettingsOpen && <SettingsTab />}
      <Container width={sidebarWidth}>
        <div
          style={{
            display: 'flex',
            gap: '12px',
            marginBottom: '1em',
            position: 'relative',
          }}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              ${isTextHidden && 'transform: translateX(4px);'}
              transition : transform 0.3s ease-in-out;
            `}
          >
            <img
              style={{
                borderRadius: '100px',
                width: '3em',
                height: '3em',
              }}
              src={workspaceIcon}
              alt="Byteark Superset Workspace Icon"
            />
          </div>
          {!isTextHidden && (
            <h4
              css={css`
                font-size: 1.5em;
                min-width: 1px;
                display: inline;
                ${transition(isCollapse)}
              `}
            >
              {workspaceName}
            </h4>
          )}
        </div>
        {!isTextHidden && <SearchInput />}
        {!isTextHidden && (
          <h6
            css={css`
              font-size: 0.8em;
              ${transition(isCollapse)}
            `}
          >
            Overview
          </h6>
        )}
        <MenuList />
        {!isTextHidden && (
          <>
            <h6
              css={css`
                font-size: 0.8em;
                margin-top: 20;
                ${transition(isCollapse)}
              `}
            >
              Favorites
            </h6>
            <div
              css={css`
                font-size: 0.8em;
                ${transition(isCollapse)}
                margin-bottom: 20;
                flex: 1 1 auto;
                height: 200px;
                overflow-y: auto;
              `}
            >
              <FavoriteSection />
            </div>
            <CreateButton />
          </>
        )}
        <Divider />
        <button
          type="button"
          onClick={handleCollapse}
          css={css`
            background: #151515;
            border-radius: 100px;
            position: absolute;
            z-index: 9999;
            width: 30px;
            height: 30px;
            border: none;
            &:hover {
              opacity: 0.7;
            }
            right: -14px;
            bottom: 50%;
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {isCollapse ? (
            <ChevronRight size="1.75rem" />
          ) : (
            <ChevronLeft size="1.75rem" />
          )}
        </button>
        <UserSection />
      </Container>
    </>
  );
}

export default Sidebar;
